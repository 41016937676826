import type { GatsbyConfig } from 'gatsby'

const getGatsbyPluginManifestOptions = (env: string): any => {
  let defaultOptions = {
    name: 'Auth',
    short_name: 'Auth',
    start_url: '/',
    background_color: '#056cf2',
    theme_color: '#056cf2',
    display: 'minimal-ui',
    icon: '',
    crossOrigin: 'use-credentials',
  }

  if (env === 'production') {
    defaultOptions.icon = 'static/logos/favicon.png'
  }
  else if (['local', 'staging'].includes(env)) {
    defaultOptions.icon = `static/logos/alternative-favicon.png`
  }

  return defaultOptions
}

const config: GatsbyConfig = {
  siteMetadata: {
    title: 'wicadu',
    titleTemplate: '%s | wicadu',
    description: 'Administra tu tienda en línea. Llega a más clientes y ve crecer tus ventas con wicadu',
    siteUrl: process.env.OAUTH_WEBAPP_HOST,
    url: process.env.OAUTH_WEBAPP_HOST,
    image: '/images/logos/favicon.png',
    twitterUsername: '@wicaduapp'
  },
  plugins: [
    {
      resolve: 'gatsby-plugin-emotion',
      options: {
        sourceMap: true,
        cssPropOptimization: true,
      },
    },
    {
      resolve: 'gatsby-plugin-manifest',
      options: getGatsbyPluginManifestOptions(
        process.env.WENV,
      ),
    },
    {
      resolve: 'gatsby-plugin-alias-imports',
      options: {
        alias: {
          '@components': 'src/components',
          '@screens': 'src/screens',
          '@containers': 'src/containers',
          '@ts-enums': 'src/ts/enums',
          '@ts-types': 'src/ts/types',
          '@HOCs': 'src/HOCs',
          '@hooks': 'src/hooks',
          '@images': 'static/images',
          '@routes': 'src/routes',
          '@utils': 'src/utils',
          '@mocks': 'src/__mocks__'
        },
        extensions: [
          'ts',
          'tsx'
        ]
      }
    },
    {
      resolve: `gatsby-plugin-google-gtag`,
      options: {
        trackingIds: [
          process.env.GOOGLE_ANALITYCS_PROJECT_ID,
        ],
        gtagConfig: {
          cookie_flags: 'SameSite=None; Secure',
        },
        pluginConfig: {
          head: false,
          respectDNT: true,
        },
      },
    },
    {
      resolve: `gatsby-plugin-env-variables`,
      options: {
        allowList: [
          'WENV',
          'VERSION',
          'OAUTH_WEBAPP_HOST',
          'OAUTH_SERVER_HOST',
          'HELP_WEBAPP_HOST',
          'SENTRY_DSN',
          'SENTRY_ENVIRONMENT',
          'SENTRY_RELEASE',
          'SENTRY_PROJECT_NAME',
          'GOOGLE_ANALITYCS_PROJECT_ID',
        ]
      }
    },
    {
      resolve: '@sentry/gatsby',
    }
  ]
}

export default config
